
<script setup lang="ts">
    export interface IProps {
        text: string;
    };

    const props = defineProps<IProps>();
    const model = defineModel<boolean>();

    const id = crypto.randomUUID();
</script>

<template>
    <div class="d-flex align-items-center gap-1 text-muted c-related-checkbox">
        <input :id="id" type="checkbox" v-model="model" style="width: 1em; height: 1em;" />
        <label :for="id">{{ text }}</label>
        <!-- <div class="form-check form-switch form-switch-md d-flex align-items-center gap-1"> -->
            <!-- <input :id="id" class="form-check-input" type="checkbox"> -->
            <!-- <label :for="id" class="form-check-label">{{ text }}</label> -->
        <!-- </div> -->
    </div>
</template>

<style scoped>
/* https://stackoverflow.com/a/60969539 */

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  /*margin-bottom: 1rem;*/ /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

</style>
